














































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Project from '@/classes/project';
import projectList from '@/content/project.list';

/* eslint-disable @typescript-eslint/no-var-requires */
const isotope = require('vueisotope');
const imagesLoaded = require('vue-images-loaded');

@Component({
  components: {
    isotope,
  },
  directives: {
    imagesLoaded,
  },
})
export default class Vision extends Vue {
  private publicPath = process.env.BASE_URL;

  private list = projectList;

  /* Collecting Projects in Theme 1 */
  private listTheme1 = projectList.filter((project: Project) => project.themes !== undefined && project.themes.includes('Theme1'));

  /* Collecting Projects in Theme 2 */
  private listTheme2 = projectList.filter((project: Project) => project.themes !== undefined && project.themes.includes('Theme2'));

  /* Collecting Projects in Theme 3 */
  private listTheme3 = projectList.filter((project: Project) => project.themes !== undefined && project.themes.includes('Theme3'));

  private options = {
    isFitWidth: true,
  };

  private layout() {
    (this.$refs.portfolioTheme1 as typeof isotope).layout('masonry');
    (this.$refs.portfolioTheme2 as typeof isotope).layout('masonry');
    (this.$refs.portfolioTheme3 as typeof isotope).layout('masonry');
  }

  private getImgUrl(fileName: string) {
    const images = require.context('@/assets/images/', true);
    return images(`./${fileName}`);
  }

  private scrollAtTop() {
    window.scrollTo(0, 0);
  }

  private scrollTo(refName: string) {
    const element = this.$refs[refName] as HTMLElement;

    window.scrollTo(0, element.offsetTop);
  }

  mounted() {
    document.title = 'Lancelot Blanchard | Vision';
  }
}
